<template>
<div>
     <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <!-- <ul class="breadcrumb bg-custom rounded shadow mb-0">
                                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ menu }}</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }} </li>
                                </ul> -->
                            </nav>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->
    <section style="margin-top: -5%" class="section">
       <div class="container bg-custom">
          <div class="row justify-content-center">
              <div class="col-12">
                  <div class="section-title text-center mb-4 pb-2">
                      <h4 class="title mb-4">{{ simple_data.title }}</h4>
                  </div>
                  <div class=" mb-4 pb-2">
                    <div class="text-muted" v-html="simple_data.description"></div>
                  </div>
              </div><!--end col-->
          </div><!--end row-->
      </div><!--end container-->
    </section>
</div>
</template>

<script>
export default {
  name: 'NoImage',
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data() {
    return {
      simple_data:{},
    }
  },
  created(){
    this.simple_data = this.sub_menu.public_data_simple?this.sub_menu.public_data_simple:{};
  },
  watch:{
    sub_menu(){
      this.simple_data = this.sub_menu.public_data_simple?this.sub_menu.public_data_simple:{};
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../assets/css/style-dark.css';
</style>
